import React, { useContext, useCallback, useMemo } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonIcon
} from '@ionic/react';
import TButtonItem from './TButtonItem';
import { car, openOutline } from 'ionicons/icons';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import {
  geolocation,
  loadingIndicator,
  appNotification,
  browser
} from '../core';
import { useDistanceMatrix } from '../hooks/';
import TMap from './TMap';

const PersonalizedLeadDirections: React.FC = () => {
  const { state, dispatch } = useContext(PersonalizedLeadContext);
  const client = state?.lead?.client;

  const { coords } = state;

  const clientCoords = useMemo(() => {
    return {
      lat: client?.lat ? parseFloat(client.lat) : null,
      lng: client?.lng ? parseFloat(client.lng) : null
    };
  }, [client]);

  const leadCoords = useMemo(() => {
    return {
      lat: coords?.latitude,
      lng: coords?.longitude
    };
  }, [coords]);

  const distance = useDistanceMatrix(leadCoords, clientCoords);

  const getUserLocation = useCallback(async () => {
    try {
      await loadingIndicator.create();
      const { coords } = (await geolocation.getCurrentPosition()) as any;
      dispatch({ coords });
    } catch (e) {
      appNotification.toast("We weren't able to get your location.", 'Error');
    } finally {
      loadingIndicator.dismiss();
    }
  }, [dispatch]);

  if (state.feature === 'appointment') {
    return null;
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle className="find-us-title">
          <span>Find us</span>
          <IonIcon
            icon={openOutline}
            onClick={() =>
              browser.open(
                leadCoords?.lat
                  ? `https://www.google.com/maps/dir/?api=1&origin=${leadCoords?.lat},${leadCoords?.lng}&destination=${clientCoords?.lat},${clientCoords?.lng}`
                  : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      `${client?.name}, ${client?.city}, ${client?.state}`
                    )}`
              )
            }
          />
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="map">
          {client && false && (
            <TMap
              coords={clientCoords}
              startCoords={leadCoords}
              infoContent={
                <IonText style={{ color: 'black' }}>
                  <strong>{client?.name}</strong>
                  <div>
                    {client?.address} {client?.address_2}
                  </div>
                  <div>
                    {client?.city}, {client?.state} {client?.postal_code}
                  </div>
                </IonText>
              }
            />
          )}
        </div>
      </IonCardContent>
      <TButtonItem
        lines="none"
        color="secondary"
        icon={car}
        onClick={getUserLocation}
      >
        Get Directions{' '}
        {distance?.duration?.text ? ` • ${distance.duration?.text}` : ''}
      </TButtonItem>
    </IonCard>
  );
};

export default PersonalizedLeadDirections;
