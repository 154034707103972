import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner } from "@ionic/react";
import DataTable from "react-data-table-component";
import clientQueueInfoService from "../services/clientQueueInfoService";
import moment from "moment";

const ClientQueueInfoPage: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchQueueInfo = async () => {
      try {
        const result = await clientQueueInfoService.list();
        setData(result); // Assuming the API response structure is correct
      } catch (error) {
        console.error("Failed to fetch client queue info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQueueInfo();
  }, []);

  // Define columns for the data table
  const columns = [
    {
      name: "Client",
      selector: (row: any) => row.client,
      sortable: true,
    },
    {
      name: "Queue Size",
      selector: (row: any) => row.queue_size,
      sortable: true,
      id: "queueSize",
    },
    {
      name: "Date Signed Up",
      sortable: true,
      id: "dateSignedUp",
      selector: (row: any) => row.date_signed_up,
      right: true,
      format: (row: any)=> moment(row.date_signed_up).format('ll'),
    },
    {
      name: "Last Human Blast",
      selector: (row: any) => (row.last_human_blast ? new Date(row.last_human_blast).toLocaleString() : "N/A"),
      sortable: true,
    },
    {
      name: "Last AI Blast",
      selector: (row: any) => (row.last_ai_blast ? new Date(row.last_ai_blast).toLocaleString() : "N/A"),
      sortable: true,
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Client Queue Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <IonSpinner />
          </div>
        ) : (
          <DataTable
            title="Queue Information"
            columns={columns}
            data={data}
            striped
            defaultSortFieldId={"queueSize"}
            defaultSortAsc={false}
            pagination
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ClientQueueInfoPage;
