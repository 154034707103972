import React, { useState, useEffect, useCallback } from 'react';
import {
  IonAccordion,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import {
  star,
  add,
  remove,
  trashOutline,
  colorWandOutline,
} from 'ionicons/icons';
import TClickToEditItem from './TClickToEditItem'; // Adjust the import based on your project structure

interface AutoReplyItemProps {
  reply: any; // You can replace 'any' with a more specific type if you have one
  isDefault: boolean;
  isNewReply?: boolean;
  handleFieldChange?: (
    replyId: number,
    fieldName: string,
    newValue: any
  ) => void;
  handleDeleteClick?: (replyId: number) => void;
  handleNewFieldChange?: (fieldName: string, newValue: any) => void;
  masterProviders: Array<{
    id: number;
    name: string;
  }>;
}

const AutoReplyItem: React.FC<AutoReplyItemProps> = ({
  reply,
  isDefault,
  handleFieldChange,
  handleNewFieldChange,
  handleDeleteClick,
  masterProviders,
  isNewReply,
}) => {

  const [selectedMasterProviders, setSelectedMasterProviders] = useState(() => reply.master_providers);

  const [editingFields, setEditingFields] = useState<
    Array<{ replyId: number; fieldName: string }>
  >([]);

  const variablesMap: any = {
    trade_message: [
      'first_name',
      'assignee',
      'company_name',
      'trade.year',
      'trade.make',
      'trade.model',
    ],
    inventory_message: [
      'first_name',
      'assignee',
      'company_name',
      'vehicle',
      'year',
      'make',
      'model',
      'link',
    ],
    service_message: ['first_name', 'assignee', 'company_name', 'offer'],
    message: ['first_name', 'last_name', 'assignee', 'company_name'],
    employment_message: ['first_name', 'last_name', 'assignee', 'company_name'],
  };

  const handleChange = useCallback((replyId: number, fieldName: string, value: any) => {
    if (isNewReply) {
      handleNewFieldChange?.(fieldName, value); // Use new handler for new replies
    } else {
      handleFieldChange?.(replyId, fieldName, value); // Existing handler for existing replies
    }
  }, [isNewReply, handleNewFieldChange, handleFieldChange]);

  useEffect(() => {
    if (isNewReply) {
      // If this is a new reply, update the newReplyData in the parent component
      handleNewFieldChange?.('master_providers', selectedMasterProviders);
    }
  }, [
    selectedMasterProviders,
    handleNewFieldChange,
    isNewReply,
  ]);

  const isFieldEditing = (replyId: number, fieldName: string) => {
    return editingFields.some(
      (field) => field.replyId === replyId && field.fieldName === fieldName
    );
  };

  const isFacebookProviderSelected = (masterProviderIds: number[]) => {
    return masterProviderIds.includes(20);
  };
  const isFacebookOnlyProviderSelected = (masterProviderIds: number[]) => {
    return masterProviderIds.length === 1 && masterProviderIds.includes(20);
  };
  const getMasterProviderNames = (ids: number[]) => {
    if (!ids.length) {
      return 'New Custom Auto Repsonse';
    }
    return ids
      .map((providerId: number) => {
        const provider: any = masterProviders?.find(
          (provider: any) => provider.id === providerId
        );
        return provider ? provider.name : '';
      })
      .join(', ');
  };
  // Toggle edit state for a field
  const toggleEditField = (replyId: number, fieldName: string) => {
    const isEditing = editingFields.some(
      (field) => field.replyId === replyId && field.fieldName === fieldName
    );
    if (isEditing) {
      setEditingFields(
        editingFields.filter(
          (field) =>
            !(field.replyId === replyId && field.fieldName === fieldName)
        )
      );
    } else {
      setEditingFields([...editingFields, { replyId, fieldName }]);
    }
  };

  const renderExpandableField = (
    replyId: number,
    label: string,
    field: string,
    value: any
  ) => {
    if (isFieldEditing(replyId, label)) {
      return (
        <>
          <IonItem
            button
            onClick={(e: any) => {
              e?.stopPropagation();
              toggleEditField(replyId, label);
            }}
          >
            <IonLabel>{label}</IonLabel>
            <IonIcon icon={remove} slot='end' />
          </IonItem>
          <TClickToEditItem
            availableVariables={variablesMap[field]}
            label={`Click below to ${
              reply.message ? 'edit' : 'set'
            } the ${label.toLowerCase()}`}
            title={label}
            text={value || ''}
            onChange={(e: any) => {
              const newValue = e?.detail?.value;
              handleChange(replyId, field, newValue);
            }}
          />
        </>
      );
    } else {
      return (
        <IonItem button onClick={() => toggleEditField(replyId, label)}>
          <IonLabel>{label}</IonLabel>
          <IonIcon icon={add} slot='end' />
        </IonItem>
      );
    }
  };

  return (
    <IonAccordion value={`reply-${reply.id}`}>
      <IonItem
        slot='header'
        color={!reply.id ? 'warning' : isDefault ? 'success' : undefined}
      >
        <IonLabel>
          {isDefault
            ? 'Default (All leads)'
            : isNewReply
            ? 'New Custom Auto Response'
            : getMasterProviderNames(selectedMasterProviders)}
        </IonLabel>
        {isDefault && <IonIcon icon={star} slot='end' />}
        {!reply.id && <IonIcon icon={colorWandOutline} slot='end' />}
        {/* Delete Button */}
        {!isNewReply && !isDefault && (
          <IonButton
            slot='end'
            fill='clear'
            size='small'
            onClick={() => handleDeleteClick?.(reply.id)}
          >
            <IonIcon icon={trashOutline} style={{ color: 'white' }} />
          </IonButton>
        )}
      </IonItem>
      <div className='reply-content' slot='content'>
        {!!isNewReply && (
          <IonItem lines='full'>
            <IonSelect
              value={selectedMasterProviders}
              placeholder='Select all providers this auto response should apply to'
              multiple={true}
              onIonChange={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                setSelectedMasterProviders(ev.detail.value);
              }}
            >
              {masterProviders.map((provider) => (
                <IonSelectOption key={provider.id} value={provider.id}>
                  {provider.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        )}

        <TClickToEditItem
          availableVariables={variablesMap['message']}
          label={`Click below to ${
            reply.message ? 'edit' : 'set'
          } the default message`}
          title={'Default Message'}
          text={reply.message}
          onChange={(e: any) => {
            const newValue = e?.detail?.value;
            handleChange(reply.id, 'message', newValue);
          }}
        />
        {/* Expandable sections for other fields */}
        {!isFacebookOnlyProviderSelected(reply.master_providers) && (
          <>
            {renderExpandableField(
              reply.id,
              'Trade Message',
              'trade_message',
              reply.trade_message
            )}
          </>
        )}
        {renderExpandableField(
          reply.id,
          'Inventory Message',
          'inventory_message',
          reply.inventory_message
        )}
        {(isDefault || isFacebookProviderSelected(reply.master_providers)) && (
          <>
            {renderExpandableField(
              reply.id,
              'Service Message',
              'service_message',
              reply.service_message
            )}
            {renderExpandableField(
              reply.id,
              'Employment Message',
              'employment_message',
              reply.employment_message
            )}
          </>
        )}
      </div>
    </IonAccordion>
  );
};

export default AutoReplyItem;
