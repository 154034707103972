import React, { useContext } from 'react';
import { util, actionSheet, browser, loadingIndicator } from '../core';
import numeral from 'numeral';
import { IonText, IonIcon, IonButton } from '@ionic/react';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import TToggleItem from './TToggleItem';
import TItem from './TItem';
import { tradesService } from '../services';
import { urlShortenerService } from '../services';
import {
  arrowUp,
  arrowUpCircleOutline,
  arrowDown,
  starOutline,
  addCircleOutline,
  ellipsisVertical
} from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';
import { AddTradeModal } from './modals';

const LeadTrades: React.FC = () => {
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const { state, removeTrade, updateAndSaveLead, dispatch } = useContext(LeadContext);
  const { lead } = state;

  if (!lead) {
    return null;
  }

  const clientId = lead?.client;
  const leadId = lead?.id;
  const leadHash = lead?.urlhash;
  const firstName = lead?.first_name;
  const trades = lead?.tradeins ?? [];
  const append = (c: string) => {
    appendMessage(c);
    util.hideLeadSidebar();
  };

  const onTradeAdded = async (trade: any) => {
    appendMessage(await tradesService.getLinkMessage(trade.report_url));
    const updated = await tradesService.updatePendingTrade(
      clientId,
      leadId,
      null
    );

    dispatch({
      type: 'set',
      value: {
        lead: updated
      }
    });
  };

  const savePendingTrade = async (trade: any) => {
    const data = await tradesService.updatePendingTrade(
      clientId,
      leadId,
      trade
    );

    dispatch({
      type: 'set',
      value: { lead: data }
    });
  };

  const badgeValue = trades?.length ? trades.length : lead?.no_trade_needed ? 'N/A' : 0;
  const badgeColor = badgeValue === 'N/A' ? 'warning': undefined;

  return (
    <TExpandableItemGroup title="Trades" badgeColor={badgeColor} badge={badgeValue}>
      {!trades?.length && (
        <TToggleItem
          label="No Trade Needed"
          checked={!!lead?.no_trade_needed}
          onIonChange={(e: any) => {
            const value = e.detail.checked ? new Date().toISOString() : null;
            const ce = new CustomEvent('', {detail: {value}});
            updateAndSaveLead('no_trade_needed', ce);
          }}
          wrapLabel
        />
      )}
      {trades?.map((trade: any, i: number) => (
        <TItem
          key={i}
          className="trade-in"
          labelElement={
            <IonText>
              <h4 className="ion-text-wrap">
                {trade.year} {trade.make} {trade.model}
              </h4>
              <p className="detail">
                Mileage: {numeral(trade.mileage).format('0,0')}
              </p>
              <p className="detail">
                VIN: {trade.vin || 'Not entered'}
              </p>
              <p className="detail trade-pricing">
                {!!trade.kbb_ico && (
                    <a
                      style={{
                        width: '123px',
                        height: '46px',
                        lineHeight: '42px',
                        textAlign: 'center',
                        paddingLeft: '29px',
                        color: '#fff',
                        textDecoration: 'none',
                        background: "url('/assets/kbb-ico-bg.png')"
                      }}
                      href={trade.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span style={{display: 'block', fontSize: '1rem'}}>{util.formatDollar(trade.kbb_ico)}</span>
                    </a>
                )}
                {!!trade.target_value && (
                  <>
                    <IonIcon icon={arrowUp} color="secondary" />
                    {util.formatDollar(trade.high_value)}
  
                    <IonIcon icon={starOutline} color="warning" />
                    {util.formatDollar(trade.target_value)}
  
                    <IonIcon icon={arrowDown} color="danger" />
                    {util.formatDollar(trade.low_value)}
                  </>
                )}
              </p>
            </IonText>
          }
        >
          <IonButton
            fill="clear"
            slot="end"
            onClick={e => {
              actionSheet.show([
                {
                  text: 'View Report',
                  handler: () => browser.open(trade.url)
                },
                {
                  text: 'Send Report',
                  handler: () => {
                    (async function() {
                      try {
                        await loadingIndicator.create();
                        append(await tradesService.getLinkMessage(trade.url));
                      } finally {
                        loadingIndicator.dismiss();
                      }
                    })();
                  }
                },
                {
                  text: 'Remove Trade',
                  role: 'destructive',
                  handler: () => {
                    removeTrade(trade.id);
                  }
                },
                {
                  text: 'Dismiss',
                  role: 'cancel'
                }
              ]);
            }}
          >
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
        </TItem>
      ))}
      <TButtonItem
        color="secondary"
        onClick={() =>
          dispatch({ type: 'set', value: { showAddTradeModal: true } })
        }
      >
        <IonIcon icon={addCircleOutline} slot="start" />
        Add Trade
      </TButtonItem>
      <TButtonItem
        color="secondary"
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const tradeUrl = `https://app.tecobi.com/d/${leadHash}/trade-in/`;
            const { short_url } = await urlShortenerService.shorten(
              tradeUrl
            );
            const message = `Okay ${firstName}, here is a link to value your trade ${short_url ?? tradeUrl}`;
            append(message);
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={arrowUpCircleOutline} slot="start" />
        Send Trade Link
      </TButtonItem>
      {state.showAddTradeModal && (
        <AddTradeModal
          isOpen={true}
          defaultPostalCode={state.leadClient?.postal_code}
          clientId={clientId}
          leadId={leadId}
          tradeInProgress={state.lead?.tradein_progress}
          onDidDismiss={() =>
            dispatch({ type: 'set', value: { showAddTradeModal: false } })
          }
          onTradeAdded={onTradeAdded}
          onTradeProgressUpdate={savePendingTrade}
        />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadTrades;
