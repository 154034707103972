import React, { useContext, useMemo, useRef, useState } from 'react';
import { IonList, IonMenu, IonContent } from '@ionic/react';
import LeadLocalInfo from './LeadLocalInfo';
import TLoading from './TLoading';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import '../styles/components/LeadSidebar.scss';
import LeadActions from './LeadActions';
import LeadAssignees from './LeadAssignees';
import LeadContactDetails from './LeadContactDetails';
import LeadMap from './LeadMap';
import LeadCredit from './LeadCredit';
import LeadDocuments from './LeadDocuments';
import LeadNotes from './LeadNotes';
import LeadTrades from './LeadTrades';
import LeadMagicLink from './LeadMagicLink';
import LeadLikes from './LeadLikes';
import LeadViewedVehicles from './LeadViewedVehicles';
import LeadOtherFields from './LeadOtherFields';
import LeadAppointment from './LeadAppointment';
import LeadVideoMeetingRoom from './LeadVideoMeetingRoom';
import LeadSurveys from './LeadSurveys';
import { useLocation } from 'react-router-dom';
import { useEventListener } from '../hooks';

const LeadSidebar: React.FC = () => {
  const menu = useRef<any>();
  const [swipeEnabled, setSwipeEnabled] = useState(true);
  const { state } = useContext(LeadContext);
  const { state: appState } = useContext(AppContext);
  const { user } = appState;
  const location = useLocation();
  const { loading } = state;
  const disabled = useMemo(
    () => !location?.pathname?.match(/\/conversation\//) || state.loading,
    [state.loading, location]
  );

  useEventListener('map:pan', ({ detail }) => setSwipeEnabled(!detail));
  return (
    <IonMenu
      ref={menu}
      disabled={disabled}
      hidden={disabled}
      side="end"
      contentId="main"
      swipeGesture={swipeEnabled}
      type="overlay"
      menuId="lead"
      className="lead-menu"
    >
      <div
        style={{
          height: 'env(safe-area-inset-top)',
          background: 'var(--ion-item-background)'
        }}
      />
      <IonContent
        forceOverscroll
        style={{ '--background': 'var(--ion-item-background)' }}
      >
        {!loading ? (
          <IonList lines="full" className="lead-info">
            <LeadLocalInfo />
            {user.is_staff && (
              <LeadNotes />
            )}
            <LeadActions />
            <LeadAssignees />
            <LeadTrades />
            <LeadDocuments />
            <LeadCredit />
            <LeadLikes />
            <LeadViewedVehicles />
            <LeadMagicLink />
            <LeadAppointment />
            <LeadMap />
            <LeadContactDetails />
            <LeadVideoMeetingRoom />
            <LeadSurveys />
            <LeadOtherFields />
          </IonList>
        ) : (
          <TLoading visible paused={false} />
        )}
      </IonContent>
    </IonMenu>
  );
};

export default LeadSidebar;
