class PixelService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "https://pixel.tecobi.com/api/tracking-pixel.gif";
  }

  public firePixel(eventName: string, options?: Record<string, string | undefined>): void {
    try {
      const params = new URLSearchParams();

      // Required parameters
      params.append("event", eventName);
      params.append("url", window.location.href);

      // Optional parameters
      if (options) {
        Object.entries(options).forEach(([key, value]) => {
          if (value) {
            params.append(key, value);
          }
        });
      }

      // Vehicle-specific fields (prefixed with `v_`)
      const vehicleFields = [
        { key: "year", param: "v_year" },
        { key: "make", param: "v_make" },
        { key: "model", param: "v_model" },
        { key: "trim", param: "v_trim" },
        { key: "vin", param: "v_vin" },
        { key: "stockNumber", param: "v_stock_number" },
        { key: "photoUrl", param: "v_photo_url" },
      ];

      vehicleFields.forEach(({ key, param }) => {
        if (options?.[key]) {
          params.append(param, options[key]!);
        }
      });

      // Create and send the tracking pixel
      const pixel = new Image();
      pixel.crossOrigin = "use-credentials";
      pixel.src = `${this.baseUrl}?${params.toString()}`;

      console.log("TECOBI Pixel Fired:", pixel.src);
    } catch (error) {
      console.error("Error firing TECOBI pixel:", error);
    }
  }
}

const pixelService = new PixelService();
export default pixelService;
