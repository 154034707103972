import React, { useContext, useEffect, useState } from "react";
import {
  IonText,
  IonGrid,
  IonRow,
  IonItem,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import './LeadViewedVehicles.css';
import { LeadContext } from "../context/LeadContext";
import TExpandableItemGroup from "./TExpandableItemGroup";

const LeadViewedVehicles: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const clientId = lead?.client;
  const leadId = lead?.id;

  useEffect(() => {
    if (!leadId || !clientId) {
      return;
    }

    const fetchViewedVehicles = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://pixel.tecobi.com/api/lead-vehicles?client_id=${clientId}&lead_id=${leadId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch vehicles.");
        }
        const data = await response.json();
        setVehicles(data);
      } catch (err: any) {
        setError(err.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchViewedVehicles();
  }, [clientId, leadId]);

  if (!lead) {
    return null;
  }

  return (
    <TExpandableItemGroup title="Viewed Vehicles" badge={vehicles.length}>
      {loading ? (
        <div className="loading">
          <IonSpinner name="crescent" />
          <IonText>Loading vehicles...</IonText>
        </div>
      ) : error ? (
        <IonText color="danger">Error: {error}</IonText>
      ) : vehicles.length === 0 ? (
        <IonRow><IonItem><IonText>No vehicles viewed for this lead.</IonText></IonItem></IonRow>
      ) : (
        <IonGrid className="viewed-vehicles-grid">
          <IonRow>
            {vehicles.map((vehicle: any, i: number) => (
              <IonCol
                key={i}
                className="vehicle-item"
                size="12"
                onClick={vehicle?.url ? () => window.open(vehicle.url, "_blank") : undefined}
              >
                <img
                  src={vehicle.vehicle_photo_url || "/assets/car-placeholder.jpg"}
                  alt={vehicle.vehicle_vin || "Vehicle"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/car-placeholder.jpg";
                  }}
                />
                <div className="detail">
                  <strong>{`${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model} ${vehicle.vehicle_trim}`}</strong>
                  <span className="stock-number">Stock: {vehicle.vehicle_stock_number || "N/A"}</span>
                  <span className="vin">VIN: {vehicle.vehicle_vin || "N/A"}</span>
                  <span className="url">Source: {vehicle?.url?.split('//')?.[1]?.split('/')?.[0] || "N/A"}</span>
                  <span className="view-count">View Count: {vehicle.view_count || 0}</span>
                  <span className="first-view">
                    First Viewed: {new Date(vehicle.first_viewed?.value).toLocaleString() || "N/A"}
                  </span>
                  <span className="last-view">
                    Last Viewed: {new Date(vehicle.last_viewed?.value).toLocaleString() || "N/A"}
                  </span>
                </div>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )}
    </TExpandableItemGroup>
  );
};

export default LeadViewedVehicles;
