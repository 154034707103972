import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./ColorVariablesPage.css";
import { util } from "../core";

const colorVariables = [
  "--ion-background-color",
  "--ion-toolbar-background",
  "--ion-color-primary",
  "--ion-color-primary-shade",
  "--ion-color-primary-tint",
  "--ion-color-secondary",
  "--ion-color-secondary-shade",
  "--ion-color-secondary-tint",
  "--ion-color-tertiary",
  "--ion-color-tertiary-shade",
  "--ion-color-tertiary-tint",
  "--ion-color-success",
  "--ion-color-success-shade",
  "--ion-color-success-tint",
  "--ion-color-warning",
  "--ion-color-warning-shade",
  "--ion-color-warning-tint",
  "--ion-color-danger",
  "--ion-color-danger-shade",
  "--ion-color-danger-tint",
  "--ion-color-dark",
  "--ion-color-dark-shade",
  "--ion-color-dark-tint",
  "--ion-color-medium",
  "--ion-color-medium-shade",
  "--ion-color-medium-tint",
  "--ion-color-light",
  "--ion-color-light-shade",
  "--ion-color-light-tint",
  "--ion-color-facebook",
  "--ion-color-facebook-shade",
  "--ion-color-facebook-tint",
  "--ion-color-orange",
  "--ion-color-orange-shade",
  "--ion-color-orange-tint",
  "--ion-color-beige",
  "--ion-color-beige-shade",
  "--ion-color-beige-tint",
];

const getComputedColor = (variable: string) => {
  const element = document.documentElement;
  return getComputedStyle(element).getPropertyValue(variable).trim();
};

const ColorVariablesPage: React.FC = () => {
  const [colorData, setColorData] = React.useState<
    { variable: string; value: string }[]
  >([]);

  React.useEffect(() => {
    const resolvedColors = colorVariables.map((variable) => ({
      variable,
      value: getComputedColor(variable),
    }));
    setColorData(resolvedColors);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Color Variables</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            {colorData.map(({ variable, value }) => (
              <IonCol
                size='6'
                size-md='3'
                key={variable}
                className='color-swatch-container'
              >
                <div
                  onClick={() => util.copyToClipboard(value)}
                  className='color-swatch'
                  style={{ backgroundColor: `var(${variable})` }}
                />
                <p className='color-name'>
                  {variable}: <span className='color-hex'>{value}</span>
                </p>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ColorVariablesPage;
