import { http } from '../core';

class ClientQueueInfoService {
  async list() {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: "/reports/client-queue-info/",
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
      return [];
    }
  }
}

const clientQueueInfoService = new ClientQueueInfoService();
export default clientQueueInfoService;
